var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"auto","height":"100px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome to MURU SMS 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Register and start sending SMS today ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Organisation Details ")])]),_c('b-form-group',{attrs:{"label":"Organisation Name","label-for":"register-org-name"}},[_c('validation-provider',{attrs:{"name":"Organisation Name","vid":"org_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-org-name","name":"register-org-name","state":errors.length > 0 ? false:null,"placeholder":"Kyanda Limited"},model:{value:(_vm.orgName),callback:function ($$v) {_vm.orgName=$$v},expression:"orgName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Organisation Billing Email","label-for":"register-org-name"}},[_c('validation-provider',{attrs:{"name":"Organisation Billing Email","vid":"org_email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-org-email","name":"register-org-email","state":errors.length > 0 ? false:null,"placeholder":"billing@example.com"},model:{value:(_vm.orgEmail),callback:function ($$v) {_vm.orgEmail=$$v},expression:"orgEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Organisation Address","label-for":"register-address"}},[_c('validation-provider',{attrs:{"name":"Organisation Address","vid":"org_address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-org-address","name":"register-org-address","state":errors.length > 0 ? false:null,"placeholder":"Nairobi, Kenya"},model:{value:(_vm.orgAddress),callback:function ($$v) {_vm.orgAddress=$$v},expression:"orgAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Admin Details ")])]),_c('b-row',{staticClass:"mx-0",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-col',{staticClass:"mx-0 px-0 cm-2",attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Firstname","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"Firstname","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false:null,"placeholder":"John"},model:{value:(_vm.userFirstname),callback:function ($$v) {_vm.userFirstname=$$v},expression:"userFirstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mx-0 px-0 cm-2",attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Lastname","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Lastname","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false:null,"placeholder":"Doe"},model:{value:(_vm.userLastname),callback:function ($$v) {_vm.userLastname=$$v},expression:"userLastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Phone Number","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"2547123456789"},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }